<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container" v-if="userType">
                <!-- Profile -->
                <base-user-profile
                    :userData="userData"
                    :user-role="userType"
                    :user-login="userLogin"
                />
                <view-zainers v-if="userType == 'user'" :userData="userData" />
                <mentor-profile
                    v-if="userType == 'mentor'"
                    :userData="userData"
                />
                <!-- Profile -->
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { UsersHelper } from '@/common/crud-helpers/users'
import MentorProfile from '@/modules/mentorship/components/mentor/MentorProfile.vue'
import ViewZainers from '@/modules/users/components/zainers/ViewZainers.vue'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        ViewZainers,
        MentorProfile,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            ideas: [],
            activeIdeasLoadding: true,
            filters: {
                page_size: 6,
                page: 1,
            },
            userType: '',
            userData: {},
            userLogin: false,
            banner: '',
        }
    },
    created() {
        this.activeIdeasLoadding = true
        if (this.$route.params.username) {
            this.userType = 'user'
            UsersHelper.get(this.$route.params.username)
            this.userLogin = false
        } else {
            this.userLogin = true
        }
        this.setUserType()
        if (this.dashboardSettings?.dashboard_tabs?.length) {
            this.setBanner()
        }
    },
    methods: {
        setUserType() {
            if (!this.$route.params.username && this.userAuth.id) {
                this.userData = cloneDeep(this.userAuth)
                if (this.userAuth.is_mentor) {
                    this.userType = 'mentor'
                } else {
                    this.userType = 'user'
                }
                if (this.userType == 'user') {
                    this.$router.replace({
                        name: 'profile-settings',
                    })
                }
            }
        },
        setBanner() {
            let activeTab = this.dashboardSettings.dashboard_tabs.find(tab =>
                tab?.value?.url?.includes('profile-settings'),
            )
            this.banner = activeTab?.value?.banner || ''
        },
    },
    computed: {
        ...mapGetters(['user', 'userAuth', 'dashboardSettings']),
    },
    watch: {
        user() {
            if (this.userType == 'user') {
                this.userData = cloneDeep(this.user)
            }
        },
        userAuth() {
            this.setUserType()
        },
        dashboardSettings() {
            this.setBanner()
        },
        $route: {
            handler(newValue, oldValue) {
                if (
                    oldValue.name === 'view-zainers' &&
                    newValue.name === 'profile'
                ) {
                    this.$router.go()
                }
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.page-contents {
    padding-bottom: rem(50px);
}
.content-wrapper {
    padding-top: rem(170px);
}
/deep/.listing__item--title {
    padding-right: rem(20px);
}
@media screen and (max-width: 767px) {
    /deep/ {
        .page-banner {
            --h: 590px;
        }
    }
}
@media screen and (max-width: 500px) {
    /deep/ {
        .page-banner {
            --h: 700px;
        }
    }
}

// ar style

.ar {
    .viewZainers {
        /deep/.listing__item--title {
            padding-left: rem(20px);
            padding-right: 0;
        }
    }
}
</style>
