<template>
    <div>
        <div class="table">
            <div class="table__head">
                <div class="table__row">
                    <div class="table__row--cell" data-width="15">
                        {{ $t('Task Number') }}
                    </div>
                    <div class="table__row--cell" data-width="15">
                        {{ $t('Task Name') }}
                    </div>
                    <div class="table__row--cell" data-width="35">
                        {{ $t('Idea Name') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="20">
                        {{ $t('Status') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="15">
                        {{ $t('Task Created by') }}
                    </div>
                </div>
            </div>
            <div class="table__body">
                <div class="table__row" :key="task.id" v-for="task in myTasks">
                    <div
                        class="table__row--cell"
                        data-width="15"
                        :data-label="$t('Task Number')"
                    >
                        #{{ task.id }}
                    </div>
                    <div
                        class="table__row--cell"
                        data-width="15"
                        :data-label="$t('Task Name')"
                    >
                        {{ task.details }}
                    </div>
                    <div
                        class="table__row--cell"
                        data-width="35"
                        :data-label="$t('Idea Name')"
                    >
                        {{ task.mentorship_obj.mentee.idea.title }}
                    </div>
                    <div
                        class="table__row--cell text-center"
                        data-width="20"
                        :data-label="$t('Status')"
                    >
                        <div>
                            <b-dropdown
                                :text="status[task.status].name"
                                :class="status[task.status].class"
                            >
                                <b-dropdown-item
                                    v-for="(s, key) in status"
                                    :key="key"
                                    :class="s.class"
                                    @click="changeStatus(key, task)"
                                >
                                    {{ s.name }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div
                        class="table__row--cell text-center"
                        data-width="15"
                        :data-label="$t('Task Created by')"
                    >
                        {{ task.creator_detail.display_name }}
                    </div>
                </div>
            </div>
            <base-loader v-if="loadding" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
export default {
    data() {
        return {
            status: {
                new: {
                    name: this.$t('New'),
                    class: 'new',
                },
                completed: {
                    name: this.$t('Completed'),
                    class: 'compeleted',
                },
                inprogress: {
                    name: this.$t('In-Progress'),
                    class: 'in-progress',
                },
            },
            next: '',
            myTasks: [],
            loadding: true,
            filters: {
                limit: 10,
                offset: 0,
            },
        }
    },
    created() {
        // XMLHttpRequest.abort()
        // console.log(this.$axiosSource.cancel())
        this.getListMyTask()
        this.getNextData()
    },
    methods: {
        changeStatus(status, task) {
            task.status = status
            MentorshipHelper.updateTask({
                uuid: this.uuid(task.mentorship_uuid),
                id: task.id,
                data: {
                    status: task.status,
                    mentorship: task.mentorship,
                },
            })
        },
        getNextData() {
            let _this = this
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.loadding) {
                    let next = this.getNextOffset(this.next)
                    if (next) {
                        this.loadding = true
                        this.filters.offset = next
                        MentorshipHelper.listMyTask(this.filters).then(resp => {
                            _this.next = resp.data.next
                            _this.listMyTask1(resp.data.results)
                        })
                    }
                }
            }
        },
        getListMyTask() {
            let _this = this
            this.loadding = true
            MentorshipHelper.listMyTask(this.filters).then(resp => {
                _this.next = resp.data.next
                _this.myTasks = []
                _this.listMyTask1(resp.data.results)
            })
        },
        listMyTask1(results) {
            let allTasks = _.concat(this.myTasks, results)
            this.myTasks = cloneDeep(allTasks)
            this.loadding = false
        },
    },
    computed: {
        ...mapGetters(['profile']),
    },
    watch: {
        // listMyTask(results) {
        //     let allTasks = _.concat(this.myTasks, results)
        //     this.myTasks = cloneDeep(allTasks)
        //     this.loadding = false
        // },
    },
}
</script>

<style lang="scss" scoped>
/deep/.table {
    .dropdown {
        .btn-secondary {
            &.dropdown-toggle {
                text-transform: none;
                font-weight: 700;
            }
        }
    }
}
</style>
