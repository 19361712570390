<template>
    <div>
        <div class="filters">
            <div class="row justify-content-between">
                <div class="col-xl-5 col-lg-6">
                    <div class="filters--keyword">
                        <input
                            type="text"
                            :placeholder="
                                $t('Search by Name, Position or any Keywords')
                            "
                            class="form-control"
                            @input="changeFilters"
                            v-model="search"
                        />
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6">
                    <div class="filters--by">
                        <div class="row align-items-center justify-content-end">
                            <div class="col-sm-3">
                                <label class="control-label">{{
                                    $t('Filter by')
                                }}</label>
                            </div>
                            <div class="col-sm-6">
                                <v-select
                                    :options="status"
                                    placeholder=""
                                    v-model="statusValue"
                                ></v-select>
                                <div
                                    v-if="statusValue.value"
                                    @click="clear"
                                    class="clear"
                                >
                                    <svg
                                        fill="#009b74"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 409.81 409.81"
                                    >
                                        <use
                                            xlink:href="#menu-close-icon"
                                        ></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            status: [
                // {
                //     text: this.$t('The Status'),
                //     value: '',
                //     disabled: true,
                // },
                // { value: 'new', text: this.$t('New') },
                // { value: 'approved', text: this.$t('Idea Approved') },
                // { value: 'workshop', text: this.$t('Sprint') },
                // { value: 'mentorship', text: this.$t('Idea Mentorship') },
                // { value: 'pitching', text: this.$t('Pitching') },
                // { value: 'rejected', text: this.$t('Rejected') },
                // { value: 'funded', text: this.$t('Funded') },
                // { value: 'resubmit', text: this.$t('Idea Resubmit') },
                {
                    text: this.$t('New'),
                    value: 'new',
                },
                {
                    text: this.$t('Completed'),
                    value: 'completed',
                },
                {
                    text: this.$t('In-Progress'),
                    value: 'inprogress',
                },
            ],
            statusValue: {
                text: this.$t('The Status'),
                value: '',
            },
            search: '',
        }
    },
    methods: {
        changeFilters() {
            this.$emit('input', {
                search: this.search,
                status: this.statusValue.value,
            })
        },
        clear() {
            this.statusValue = {
                text: this.$t('The Status'),
                value: '',
            }
            // this.changeFilters()
        },
    },
    watch: {
        statusValue: function() {
            this.changeFilters()
        },
    },
}
</script>

<style lang="scss" scoped>
.filters {
    > div {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &--keyword {
        .form-control {
            background-image: url("data:image/svg+xml,%3Csvg fill='%236e337a' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
            background-size: 25px;
            padding-left: 45px;
            background-repeat: no-repeat;
            background-position: left 15px center;
            color: var(--primary);
            height: 55px;
            line-height: 55px;
            -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
            &::placeholder {
                font-size: rem(18px);
                color: var(--primary);
            }
        }
    }
    &--by {
        /deep/ {
            .v-select {
                .arrow-down {
                    margin: 0;
                }
                .v-select-toggle {
                    padding: rem(13px) rem(20px) !important;
                    color: var(--primary) !important;
                    font-size: rem(18px) !important;
                    font-weight: 600 !important;
                    height: 55px !important;
                    line-height: 36px;
                    -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
                    &:focus,
                    &:active,
                    &.active {
                        color: #fff !important;
                    }
                }
            }
        }

        label {
            color: var(--primary);
            font-size: rem(22px);
            font-weight: 700;
            margin: 0;
        }
    }
    .clear {
        width: 13px;
        position: absolute;
        top: 24px;
        right: 80px;
        cursor: pointer;
    }
    @media screen and (max-width: 992px) and (min-width: 768px) {
        .filters {
            &--by {
                > .row {
                    margin-top: rem(30px);
                    justify-content: space-between !important;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        margin-bottom: rem(40px);
        &--by {
            /deep/ {
                .v-select {
                    .v-select-toggle {
                        height: 45px !important;
                        line-height: 28px;
                    }
                }
            }
        }
        &--keyword {
            .form-control {
                height: 45px;
                line-height: 45px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        justify-content: center;
        &--by {
            padding-top: rem(20px);
            > .row {
                justify-content: center !important;
            }
        }
    }
    @media screen and (max-width: 575px) {
        &--by {
            label {
                margin: 0 0 rem(16px) 0;
            }
        }
    }
    /deep/ {
        @media screen and (max-width: 991px) {
            .clear {
                right: 60px;
            }
        }
    }
}

// ar style

.ar .filters--keyword .form-control {
    padding-left: 1.375rem;
    padding-right: 45px;
    background-position: right 15px center;
}
.ar {
    .filters {
        /deep/ {
            .clear {
                left: 80px;
                right: auto;
            }
            @media screen and (max-width: 991px) {
                .clear {
                    left: 60px;
                    right: auto;
                }
            }
        }
    }
}
</style>
