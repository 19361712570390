<template>
    <div>
        <div class="table">
            <div class="table__head">
                <div class="table__row">
                    <div class="table__row--cell" data-width="10">
                        {{ $t('Idea ID') }}
                    </div>
                    <div class="table__row--cell" data-width="26">
                        {{ $t('Idea Name') }}
                    </div>
                    <div class="table__row--cell" data-width="10">
                        {{ $t('Idea Status') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="18">
                        {{ $t('Mentorship Status') }}
                    </div>
                    <div class="table__row--cell" data-width="15">
                        {{ $t('Owner name') }}
                    </div>
                    <div class="table__row--cell" data-width="11">
                        {{ $t('Last Update') }}
                    </div>
                    <div class="table__row--cell text-center" data-width="10">
                        {{ $t('Pending task') }}
                    </div>
                </div>
            </div>
            <div class="table__body">
                <div
                    class="table__row"
                    v-bind:key="key"
                    v-for="(data, key) in mentorships"
                >
                    <div
                        class="table__row--cell"
                        data-width="10"
                        :data-label="$t('Idea ID')"
                    >
                        #{{ data.mentee.object_id }}
                    </div>
                    <div
                        class="table__row--cell"
                        data-width="26"
                        :data-label="$t('Idea Name')"
                    >
                        {{ data.mentee.title }}
                    </div>
                    <div
                        class="table__row--cell text-lowercase"
                        data-width="10"
                        :data-label="$t('Idea Status')"
                    >
                        {{
                            $t(
                                ideasStatusList[
                                    data.mentee.object_details.state
                                ].text,
                            )
                        }}
                    </div>
                    <div
                        class="table__row--cell text-center"
                        data-width="18"
                        :data-label="$t('Mentorship Status')"
                    >
                        <span
                            class="status-title"
                            v-if="data.status == 'completed'"
                            >{{ status[data.status].name }}</span
                        >
                        <div class="mentor-dropdown-parent" v-else>
                            <b-dropdown
                                :text="status[data.status].name"
                                :class="status[data.status].class"
                            >
                                <b-dropdown-item
                                    v-for="(s, key) in status"
                                    :key="key"
                                    :class="s.class"
                                    @click="changeStatus(data, key)"
                                >
                                    {{ s.name }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div
                        class="table__row--cell"
                        data-width="15"
                        :data-label="$t('Owner name')"
                    >
                        {{
                            data.mentee.idea.owner_details
                                ? data.mentee.idea.owner_details.display_name
                                : ''
                        }}
                    </div>
                    <div
                        class="table__row--cell"
                        data-width="11"
                        :data-label="$t('Last Update')"
                    >
                        <span class="ltr">{{ data.modified | dateTime }}</span>
                        <br />
                        <span class="ltr">{{
                            data.modified | dateDayMonthYear
                        }}</span>
                    </div>
                    <div
                        class="table__row--cell text-center"
                        data-width="10"
                        :data-label="$t('Pending task')"
                    >
                        <span class="ltr">{{ data.pending_tasks }}</span>
                    </div>
                    <router-link
                        :to="{
                            name: 'mentorship-details',
                            params: {
                                uuid: data.uuid,
                            },
                        }"
                        class="abs-link"
                    >
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
export default {
    props: {
        mentorships: Array,
    },
    data() {
        return {
            status: {
                new: {
                    name: this.$t('New'),
                    class: 'new',
                },
                completed: {
                    name: this.$t('Completed'),
                    class: 'compeleted',
                },
                inprogress: {
                    name: this.$t('In-Progress'),
                    class: 'in-progress',
                },
            },
        }
    },
    methods: {
        changeStatus(data, status) {
            MentorshipHelper.updateMyMentorship({
                uuid: this.uuid(data.uuid),
                data: {
                    status: status,
                },
            }).then(resp => {
                data.status = resp.data.status
            })
        },
    },
    computed: {
        ...mapGetters(['ideasStatusList']),
    },
}
</script>

<style lang="scss" scoped>
.table__row:hover .mentor-dropdown-parent {
    position: relative;
    z-index: 999;
}
/deep/.table {
    .dropdown {
        .btn-secondary {
            &.dropdown-toggle {
                text-transform: none;
                font-weight: 700;
            }
        }
    }
}
span.status-title {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: capitalize;
    font-size: rem(20px);
    font-weight: 600;
    color: #4a1885 !important;
}
</style>
