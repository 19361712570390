<template>
    <div class="page-contents">
        <div class="viewZainers">
            <!-- Ideas Listing -->
            <ideas-card
                v-for="idea in ideas"
                :idea="idea"
                :key="idea.id"
                :description="true"
                :date="true"
                :showReviews="false"
                :showIdeaState="false"
                :to="{
                    name: 'idea-details',
                    params: { uuid: idea.uuid },
                }"
            ></ideas-card>
            <base-loader v-if="activeIdeasLoadding" />
            <!-- Ideas Listing -->
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import IdeasCard from '@/modules/ideas/components/list/IdeasCard'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    props: {
        userData: Object,
    },
    components: {
        IdeasCard,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            ideas: [],
            activeIdeasLoadding: true,
            filters: {
                page_size: 6,
                page: 1,
                state: 'approved,planned,implemented',
            },
            exist: false,
        }
    },
    created() {
        this.activeIdeasLoadding = true
        this.getIdea()
    },
    methods: {
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.activeIdeasLoadding) {
                    let next = this.getNextPage(this.ideasList.next)
                    if (next) {
                        this.activeIdeasLoadding = true
                        this.filters.page = next
                        IdeasHelper.listIdea(this.filters)
                    }
                }
            }
        },
        getIdea() {
            if (this.userData.id && !this.exist) {
                this.filters.owner_or_member = this.userData.id
                IdeasHelper.listIdea(this.filters)
                this.exist = true
            }
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['ideasList']),
    },
    watch: {
        userData() {
            this.getIdea()
        },
        ideasList() {
            let allIdeas = _.concat(this.ideas, this.ideasList.results)
            this.ideas = cloneDeep(allIdeas)
            this.activeIdeasLoadding = false
        },
    },
}
</script>

<style lang="scss" scoped>
.page-contents {
    padding-bottom: rem(50px);
}
.viewZainers {
    padding-top: rem(40px);
}
/deep/.listing__item--title {
    padding-right: rem(20px);
}
/deep/ .listing__item--image {
    top: rem(-100px);
    img {
        max-width: max-content;
        object-fit: contain;
        height: auto;
    }
    @media screen and (max-width: 767px) {
        top: rem(-60px);
        img {
            position: absolute;
            left: 48%;
            transform: translateX(-50%);
            &.image-top {
                position: relative;
                left: 50%;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    /deep/ {
        .page-banner {
            --h: 590px;
        }
    }
}
@media screen and (max-width: 500px) {
    /deep/ {
        .page-banner {
            --h: 700px;
        }
    }
}

// ar style

.ar {
    .viewZainers {
        /deep/.listing__item--title {
            padding-left: rem(20px);
            padding-right: 0;
        }
        /deep/ .listing__item--image {
            @media screen and (max-width: 767px) {
                img {
                    right: 48%;
                    left: auto;
                    transform: translateX(50%);
                    &.image-top {
                        position: relative;
                        right: 50%;
                        left: auto;
                    }
                }
            }
        }
    }
}
</style>
