<template>
    <div class="addTask">
        <div class="position-relative">
            <div class="greyBg"></div>
            <h2 class="section-title">{{ $t('Add Tasks') }}</h2>
            <base-form @submit="taskSubmit">
                <div class="row">
                    <div class="col-xl-6 col-md-4">
                        <b-form-group>
                            <label class="control-label text-capitalize">{{
                                $t('Idea title')
                            }}</label>
                            <base-select
                                name="idea"
                                :options="menteesOptions"
                                @input="changeIdea"
                            />
                            <div class="invalid-feedback" v-if="errors.idea">
                                {{ $t('This field is required') }}
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-xl-3 col-md-4">
                        <b-form-group>
                            <label class="control-label">{{
                                $t('Assign To')
                            }}</label>
                            <base-select
                                name="assignee"
                                :options="assignToOptions"
                                @input="changeAssignee"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.assignee"
                            >
                                {{ $t('This field is required') }}
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-xl-3 col-md-4">
                        <b-form-group>
                            <label class="control-label">{{
                                $t('Due Date')
                            }}</label>
                            <vuejsDatepicker
                                :bootstrap-styling="true"
                                :clear-button="true"
                                v-model="task.due_date"
                                :disabledDates="disabledDates"
                            ></vuejsDatepicker>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12">
                        <b-form-group>
                            <label class="control-label">{{
                                $t('Task Detail')
                            }}</label>
                            <b-form-textarea
                                name="details"
                                v-model="task.details"
                                placeholder=""
                                @input="changeDetails"
                            ></b-form-textarea>
                            <div class="invalid-feedback" v-if="errors.details">
                                {{ $t('This field is required') }}
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-sm-6">
                        <b-form-group>
                            <base-drop-zone
                                :label="
                                    $t('Upload your Idea Proposal Document')
                                "
                                :dropzonesettings="{
                                    acceptedFiles: '.pdf',
                                }"
                                name="documents"
                                @input="inputDocuments"
                                @deleteInput="deletedocuments"
                            ></base-drop-zone>
                        </b-form-group>
                    </div>
                </div>
                <div class="button-row">
                    <b-button
                        @click="taskSubmit"
                        variant="primary"
                        v-if="buttonShow"
                        >{{ $t('Submit') }}</b-button
                    >
                </div>
            </base-form>
        </div>
    </div>
</template>

<script>
import vuejsDatepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { MentorshipHelper } from '@/common/crud-helpers/mentorship'

export default {
    components: {
        vuejsDatepicker,
    },
    data() {
        return {
            buttonShow: true,
            menteesOptions: [],
            assignToOptions: [],
            idea: '',
            task: {},
            documents: [],
            errors: {
                idea: false,
                assignee: false,
                details: false,
            },
            filters: {
                limit: 9999999,
                offset: 0,
                in_progress: true,
            },
            disabledDates: {
                to: new Date(Date.now() - 8640000),
            },
        }
    },
    created() {
        MentorshipHelper.list(this.filters)
    },
    methods: {
        setMenteesOptions() {
            this.menteesOptions = []
            this.mentorshipList.results.forEach(mentorship => {
                this.menteesOptions.push({
                    value: mentorship.mentee.idea.id,
                    text: mentorship.mentee.idea.title,
                })
            })
        },
        changeIdea(value) {
            this.idea = value
            IdeasHelper.getByIdIdea(this.idea)
            this.errors.idea = false
        },
        changeAssignee(value) {
            this.errors.assignee = false
            this.task.assignee = value
        },
        changeDetails() {
            this.errors.details = this.task.details ? false : true
        },
        inputDocuments(data) {
            this.documents.push(data)
        },
        deletedocuments() {
            this.documents = []
        },
        taskSubmit() {
            this.errors.idea = this.idea ? false : true
            this.errors.assignee = this.task.assignee ? false : true
            this.errors.details = this.task.details ? false : true

            if (this.idea && this.task.assignee && this.task.details) {
                this.buttonShow = false
                let uuid = this.profile.mentees.find(
                    option => option.object_id == this.idea,
                ).first_mentorship_uuid

                MentorshipHelper.createTask({
                    uuid: this.uuid(uuid),
                    assignee: this.task.assignee,
                    due_date: this.task.due_date,
                    details: this.task.details,
                    attachments: this.documents,
                }).then(() => {
                    this.$emit('changeTab', 2)
                    this.buttonShow = true
                })
            }
        },
    },
    computed: {
        ...mapGetters(['profile', 'ideaObject', 'mentorshipList']),
    },
    watch: {
        ideaObject() {
            this.assignToOptions = []
            this.ideaObject.members.forEach(member => {
                this.assignToOptions.push({
                    value: member.user_detail.id,
                    text: member.user_detail.display_name,
                })
            })
        },
        mentorshipList() {
            this.setMenteesOptions()
        },
    },
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
    display: block;
}
/deep/ {
    .filesList {
        padding: 0;
    }
}
</style>
