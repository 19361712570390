<template>
    <b-tabs class="ideas-tabs">
        <b-tab :title="$t('On-going Mentorship')" @click="clickTab(0)"> </b-tab>
        <b-tab :title="$t('Mentorship history')" active @click="clickTab(1)">
            <!-- Filters -->
            <filters @input="changeFilters"></filters>
            <!-- Filters -->

            <div class="position-relative">
                <div class="greyBg"></div>
                <!-- table -->
                <mentor-data :mentorships="mentorships"></mentor-data>
                <!-- table -->
                <base-loader v-if="loadding" />
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
import Filters from '@/modules/mentorship/components/mentor/Filters.vue'
import MentorData from '@/modules/mentorship/components/mentor/MentorData.vue'

export default {
    components: {
        Filters,
        MentorData,
    },
    data() {
        return {
            mentorships: [],
            loadding: true,
            filters: {
                limit: 5,
                offset: 0,
                is_history: true,
            },
        }
    },
    created() {
        MentorshipHelper.list(this.filters)
        this.loadding = true
    },
    methods: {
        changeFilters: debounce(function(val, event) {
            this.mentorships = []
            if (event) event.preventDefault()
            this.filters.search = val.search
            this.filters.status = val.status
            this.filters.limit = 5
            this.filters.offset = 0
            MentorshipHelper.list(this.filters)
        }, 300),
        clickTab(tab) {
            this.$emit('changeTab', tab)
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.loadding) {
                    let next = this.getNextOffset(this.mentorshipList.next)

                    if (next) {
                        this.filters.offset = next
                        this.loadding = true
                        MentorshipHelper.list(this.filters)
                    }
                }
            }
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['mentorshipList']),
    },
    watch: {
        mentorshipList() {
            if (this.filters.offset == 0) {
                this.mentorships = []
            }
            let all = _.concat(this.mentorships, this.mentorshipList.results)
            this.mentorships = cloneDeep(all)
            this.loadding = false
        },
    },
}
</script>

<style lang="scss" scoped></style>
