<template>
    <div class="mentees-section">
        <div class="position-relative">
            <div class="greyBg"></div>
            <h3>{{ $t('Mentees') }}</h3>
            <div class="table">
                <div class="table__head">
                    <div class="table__row">
                        <div
                            class="table__row--cell text-center"
                            data-width="15"
                        >
                            {{ $t('ID Number') }}
                        </div>
                        <div class="table__row--cell" data-width="45">
                            {{ $t('Owner Name') }}
                        </div>
                        <div class="table__row--cell" data-width="40">
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div class="table__body">
                    <div
                        class="table__row"
                        v-bind:key="mentee.id"
                        v-for="mentee in profile.mentees_owner"
                    >
                        <div
                            class="table__row--cell text-center"
                            data-width="15"
                            :data-label="$t('ID Number')"
                        >
                            #{{ mentee.id }}
                        </div>
                        <div
                            class="table__row--cell"
                            data-width="45"
                            :data-label="$t('Owner Name')"
                        >
                            {{ mentee.display_name }}
                        </div>
                        <div class="table__row--cell" data-width="40">
                            <ul class="list-unstyled">
                                <li>
                                    <router-link
                                        :to="{
                                            name: 'messages',
                                            params: {
                                                username: mentee.username,
                                            },
                                        }"
                                    >
                                        <div class="icon">
                                            <svg
                                                fill="currentColor"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 512 512"
                                                style="
                                                    enable-background: new 0 0
                                                        512 512;
                                                "
                                                xml:space="preserve"
                                            >
                                                <use
                                                    xlink:href="#mentees-message-icon"
                                                ></use>
                                            </svg>
                                        </div>
                                        {{ $t('Message') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        :to="{
                                            name: 'view-zainers',
                                            params: {
                                                username: mentee.username,
                                            },
                                        }"
                                    >
                                        <div class="icon">
                                            <svg
                                                fill="currentColor"
                                                data-name="Layer 1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 287.63 288.01"
                                            >
                                                <use
                                                    xlink:href="#mentees-profile-icon"
                                                ></use>
                                            </svg>
                                        </div>
                                        {{ $t('Profile') }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['profile']),
    },
}
</script>

<style lang="scss" scoped>
.mentees-section {
    margin-top: rem(55px);
    margin-bottom: rem(55px);
    .position-relative {
        h3 {
            font-size: rem(52px);
            font-weight: 900;
            color: var(--primary);
            margin-bottom: rem(80px);
            text-transform: uppercase;
            @media screen and (max-width: 991px) {
                font-size: rem(40px);
            }
            @media screen and (max-width: 767px) {
                font-size: rem(30px);
            }
        }
    }
}
ul {
    @include flex(center, flex-start);
    li {
        position: relative;
        &:not(:last-child) {
            margin-right: rem(60px);
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                height: 35px;
                background: var(--primary);
                right: -30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .icon {
            color: #009b74;
            margin-right: 12px;
            svg {
                width: 22px;
                height: auto;
            }
        }
        a {
            color: #009b74;
            font-size: rem(14px);
            font-weight: 700;
            text-transform: uppercase;
            @include flex(center, flex-start);
        }
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        margin-right: 0.9375rem;
        justify-content: space-between;
        li {
            &:not(:last-child) {
                margin: 0;
                &:after {
                    display: none !important;
                }
            }
            a {
                flex-direction: column;
            }
            .icon {
                margin: 0 0 8px 0;
            }
        }
    }
}
/deep/.table {
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.16);
    max-width: 840px;
    padding: 0 rem(35px);
    padding-bottom: rem(16px);
    &__head {
        &__row {
            border-bottom: 1px solid #fdffd5 !important;
        }
    }
    &__row {
        border-bottom: 1px solid #fdffd5 !important;
        &:last-child {
            border: 0;
        }
        &--cell {
            color: #323232;
            padding-top: rem(28px);
            padding-bottom: rem(28px);
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                ul {
                    li {
                        a {
                            color: #fff;
                        }
                        .icon {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        &__row {
            &--cell {
                &:before {
                    padding-top: rem(30px) !important;
                    padding-bottom: rem(30px) !important;
                }
            }
        }
    }
}

// ar style

.ar ul li:not(:last-child) {
    margin-left: 3.75rem;
    margin-right: 0;
}
.ar ul li .icon {
    margin-left: 12px;
    margin-right: 0;
}
.ar ul li:not(:last-child):after {
    left: -30px;
    right: auto;
}
.ar {
    @media screen and (max-width: 500px) {
        margin-left: 0.9375rem;
        margin-right: 0;
        li {
            &:not(:last-child) {
                margin: 0;
            }
            .icon {
                margin: 0 0 8px 0;
            }
        }
    }
}
</style>
