var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mentees-section"},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"greyBg"}),_c('h3',[_vm._v(_vm._s(_vm.$t('Mentees')))]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table__head"},[_c('div',{staticClass:"table__row"},[_c('div',{staticClass:"table__row--cell text-center",attrs:{"data-width":"15"}},[_vm._v(" "+_vm._s(_vm.$t('ID Number'))+" ")]),_c('div',{staticClass:"table__row--cell",attrs:{"data-width":"45"}},[_vm._v(" "+_vm._s(_vm.$t('Owner Name'))+" ")]),_c('div',{staticClass:"table__row--cell",attrs:{"data-width":"40"}},[_vm._v("   ")])])]),_c('div',{staticClass:"table__body"},_vm._l((_vm.profile.mentees_owner),function(mentee){return _c('div',{key:mentee.id,staticClass:"table__row"},[_c('div',{staticClass:"table__row--cell text-center",attrs:{"data-width":"15","data-label":_vm.$t('ID Number')}},[_vm._v(" #"+_vm._s(mentee.id)+" ")]),_c('div',{staticClass:"table__row--cell",attrs:{"data-width":"45","data-label":_vm.$t('Owner Name')}},[_vm._v(" "+_vm._s(mentee.display_name)+" ")]),_c('div',{staticClass:"table__row--cell",attrs:{"data-width":"40"}},[_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('router-link',{attrs:{"to":{
                                        name: 'messages',
                                        params: {
                                            username: mentee.username,
                                        },
                                    }}},[_c('div',{staticClass:"icon"},[_c('svg',{staticStyle:{"enable-background":"new 0 0"},attrs:{"fill":"currentColor","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 512 512","xml:space":"preserve"}},[_c('use',{attrs:{"xlink:href":"#mentees-message-icon"}})])]),_vm._v(" "+_vm._s(_vm.$t('Message'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                        name: 'view-zainers',
                                        params: {
                                            username: mentee.username,
                                        },
                                    }}},[_c('div',{staticClass:"icon"},[_c('svg',{attrs:{"fill":"currentColor","data-name":"Layer 1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 287.63 288.01"}},[_c('use',{attrs:{"xlink:href":"#mentees-profile-icon"}})])]),_vm._v(" "+_vm._s(_vm.$t('Profile'))+" ")])],1)])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }