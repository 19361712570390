<template>
    <div class="mentorDashboard">
        <div class="mentorDashboard__summary">
            <div class="mentorDashboard__summary--left">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item pink">
                        <a
                            class="nav-link"
                            :class="{ active: isActive(0) }"
                            v-on:click.stop.prevent="tabIndex = 0"
                            id="ongoing"
                            data-toggle="tab"
                            href="#ongoing"
                            role="tab"
                            aria-controls="ongoing"
                            aria-selected="true"
                        >
                            {{ profile.on_going_mentorship }}
                            <span>
                                {{ $t('On-going Mentorship').split(' ')[0]
                                }}<br />{{
                                    $t('On-going Mentorship').split(' ')[1]
                                }}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item yellow">
                        <a
                            class="nav-link"
                            :class="{ active: isActive(1) }"
                            v-on:click.stop.prevent="tabIndex = 1"
                            id="history"
                            data-toggle="tab"
                            href="#history"
                            role="tab"
                            aria-controls="history"
                            aria-selected="false"
                        >
                            {{ profile.history_mentorship }}
                            <span>
                                {{ $t('Mentorship history').split(' ')[0]
                                }}<br />{{
                                    $t('Mentorship history').split(' ')[1]
                                }}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item blue">
                        <a
                            class="nav-link"
                            :class="{ active: isActive(2) }"
                            v-on:click.stop.prevent="tabIndex = 2"
                            id="tasks"
                            data-toggle="tab"
                            href="#tasks"
                            role="tab"
                            aria-controls="tasks"
                            aria-selected="false"
                        >
                            {{ profile.assigned_task }}
                            <span>
                                {{ $t('Assigned Tasks').split(' ')[0] }}<br />
                                {{ $t('Assigned Tasks').split(' ')[1] }}
                            </span>
                        </a>
                    </li>
                    <li class="nav-item white">
                        <a
                            class="nav-link"
                            :class="{ active: isActive(3) }"
                            v-on:click.stop.prevent="tabIndex = 3"
                            id="mentees"
                            data-toggle="tab"
                            href="#mentees"
                            role="tab"
                            aria-controls="mentees"
                            aria-selected="false"
                        >
                            {{ profile.mentee_owner_count }}
                            <span>
                                {{ $t('Number Of') }}<br />
                                {{ $t('The Mentees') }}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mentorDashboard__summary--right">
                <a
                    href="#"
                    class="mentorDashboard--addtask"
                    :class="{ active: isActive(4) }"
                    v-on:click.stop.prevent="tabIndex = 4"
                    id="addtasks"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="addtasks"
                    aria-selected="false"
                >
                    <div class="icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            xml:space="preserve"
                            width="18.1902mm"
                            height="18.1902mm"
                            version="1.1"
                            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
                            viewBox="0 0 448.19 448.19"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                        >
                            <use xlink:href="#plus-icon"></use>
                        </svg>
                    </div>
                    {{ $t('Add Tasks') }}
                </a>
            </div>
        </div>
        <div class="mentorDashboard__contents">
            <b-tabs nav-wrapper-class="d-none">
                <b-tab>
                    <component
                        :is="currentTabComponent"
                        @changeTab="changeTab"
                    />
                </b-tab>
                <!--
                <b-tab>
                    
                </b-tab> -->
            </b-tabs>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { MentorshipHelper } from '@/common/crud-helpers/mentorship'
import AddTask from '@/modules/mentorship/components/mentor/AddTask.vue'
import Mentees from '@/modules/mentorship/components/mentor/Mentees.vue'
import MentorshipsHistory from '@/modules/mentorship/components/mentor/MentorshipsHistory.vue'
import MentorshipsProgress from '@/modules/mentorship/components/mentor/MentorshipsProgress.vue'
import TaskList from '@/modules/mentorship/components/mentor/TaskList.vue'

export default {
    components: {
        Mentees,
        MentorshipsProgress,
        MentorshipsHistory,
        TaskList,
        AddTask,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: 'profile',
            },
            tabIndex: 0,
        }
    },
    created() {
        MentorshipHelper.getProfile()
        if (this.$route.query.addTask) {
            this.tabIndex = 4
        }
    },
    methods: {
        changeTab(tab) {
            this.tabIndex = tab
        },
        isActive(index) {
            return this.tabIndex == index
        },
    },
    computed: {
        ...mapGetters(['profile']),
        currentTabComponent: function() {
            if (this.tabIndex == 0) {
                return 'mentorships-progress'
            } else if (this.tabIndex == 1) {
                return 'mentorships-history'
            } else if (this.tabIndex == 2) {
                return 'task-list'
            } else if (this.tabIndex == 3) {
                return 'mentees'
            } else if (this.tabIndex == 4) {
                return 'add-task'
            } else {
                return ''
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.mentorDashboard {
    margin-bottom: rem(100px);
    /deep/.tab-content {
        padding-left: 0;
        padding-right: 0;
    }
    $self: &;
    &__summary {
        background: rgb(150, 22, 158);
        background: linear-gradient(
            318deg,
            rgba(150, 22, 158, 1) 0%,
            rgba(6, 28, 114, 1) 100%
        );
        opacity: 1;
        padding: rem(48px) rem(65px);
        padding-right: rem(80px);
        @include flex(center, space-between);
        -webkit-box-shadow: -8px 6px 10px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: -8px 6px 10px 0px rgba(0, 0, 0, 0.35);
        box-shadow: -8px 6px 10px 0px rgba(0, 0, 0, 0.35);
        &--left {
            flex: 0 0 65%;
            max-width: 65%;
            .nav-tabs {
                border: 0px;
            }
            .nav-item {
                --tabcolor: #fff;
                box-shadow: none;
                background: rgba(256, 256, 256, 0.1);
                flex: 1;
                border-radius: 0;
                position: relative;
                border-bottom: solid 3px var(--tabcolor);
                &:after {
                    position: absolute;
                }
                .nav-link {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: rem(0px) rem(5px) rem(0px) rem(5px);
                    height: 150px;
                    border-radius: 0;
                    color: var(--tabcolor);
                    font-size: rem(57px);
                    font-weight: 900;
                    span {
                        display: block;
                        text-align: center;
                        color: #fff;
                        font-weight: 400;
                        font-size: rem(16px);
                        text-transform: uppercase;
                        padding-top: rem(6px);
                        line-height: 1.2;
                    }
                    &.active {
                        background: var(--tabcolor);
                        color: #fff;
                        border-color: transparent;
                    }
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            background: var(--tabcolor);
                            color: #fff;
                            border-color: transparent;
                        }
                    }
                }
                &:not(:last-child) {
                    margin-right: rem(30px);
                }
                &.pink {
                    --tabcolor: var(--pink);
                }
                &.yellow {
                    --tabcolor: var(--yellow);
                }
                &.blue {
                    --tabcolor: #0bb3f1;
                }
                &.white {
                    .nav-link {
                        &.active {
                            color: #68178e;
                            span {
                                color: #68178e;
                            }
                        }
                        @media screen and (min-width: 1025px) {
                            &:hover {
                                color: #68178e;
                                span {
                                    color: #68178e;
                                }
                            }
                        }
                    }
                }
            }
        }
        #{ $self }--addtask {
            color: #fff;
            font-size: rem(36px);
            font-weight: 900;
            text-transform: uppercase;
            @include flex(center, flex-start);
            .icon {
                margin-right: rem(20px);
                svg {
                    max-width: 50px;
                    height: auto;
                }
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: var(--secondary);
                    text-decoration: none;
                }
            }
        }
    }
    &__contents {
        /deep/ .tab-content {
            margin: 0px;
            background: none;
            border: 0;
            box-shadow: none;
        }
    }
    .ongoing-mentor {
        .greyBg {
            width: 660px;
        }
        .search-filters {
            padding-top: rem(30px);
            .search {
                .form-control {
                    height: 55px;
                    line-height: 55px;
                    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.35);
                    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.35);
                    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.35);
                }
            }
            .status {
                border: 1px solid #000;
                .v-select {
                    height: 55px !important;
                    .arrow-down {
                        margin: 0;
                    }
                }
                .v-select-toggle {
                    padding-top: rem(13px) !important;
                    padding-bottom: rem(13px) !important;
                    height: 55px !important;
                    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.35) !important;
                    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.35) !important;
                    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.35) !important;
                }
            }
        }
    }
    /deep/ {
        .filters--by {
            .v-select {
                .arrow-down {
                    zoom: 0.6;
                }
            }
        }
        .ideas-tabs {
            .nav-tabs {
                .nav-item {
                    .nav-link {
                        font-weight: 700;
                        padding-left: rem(50px);
                        padding-right: rem(50px);
                    }
                }
            }
            .tab-content {
                padding-top: rem(30px);
            }
        }
    }
    .mentees {
        margin-top: rem(35px);
        /deep/.section-title {
            margin-bottom: rem(40px);
        }
    }
    .assignTask {
        margin-top: rem(35px);
    }
    /deep/.addTask {
        margin-top: rem(35px);
        .section-title {
            margin-bottom: rem(65px);
        }
        .v-select-toggle {
            @media screen and (max-width: 767px) {
                -webkit-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
            }
            .arrow-down {
                zoom: 0.6;
                right: 35px;
            }
        }
        .vdp-datepicker {
            .form-control {
                @media screen and (min-width: 768px) {
                    box-shadow: none;
                }
                -webkit-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.2);
            }
        }
        .greyBg {
            --h: 480px;
        }
        .filesList {
            &__items {
                padding: 0 0 rem(60px) 0;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        &__summary {
            padding-right: rem(45px);
            padding-left: rem(45px);
        }
    }
    @media screen and (max-width: 991px) {
        &__summary {
            flex-direction: column;
            &--left {
                max-width: 100%;
                flex: 0 0 100%;
                width: 100%;
                .nav-item {
                    .nav-link {
                        height: 125px;
                        font-size: rem(45px);
                    }
                }
            }
            &--right {
                margin-top: rem(30px);
            }
            /deep/.mentorDashboard {
                &--addtask {
                    font-size: rem(28px);
                    .icon {
                        svg {
                            max-width: 35px;
                        }
                    }
                }
            }
        }
        /deep/ {
            .ideas-tabs {
                .nav-tabs {
                    .nav-item {
                        .nav-link {
                            padding: rem(20px);
                            font-size: rem(25px);
                        }
                    }
                }
            }
            .greyBg {
                display: none;
            }
            .addTask {
                .vdp-datepicker {
                    .form-control {
                        height: 58px !important;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        /deep/ {
            .ideas-tabs {
                .nav-tabs {
                    .nav-item {
                        max-width: 50%;
                        flex: 0 0 50%;
                        text-align: center;
                        .nav-link {
                            padding: rem(18px) 0;
                            font-size: rem(16px);
                        }
                    }
                }
            }
        }
        &__summary--left {
            /deep/.nav-tabs {
                flex-direction: column;
                .nav-item {
                    width: 100%;
                    .nav-link {
                        flex-direction: row;
                        height: 60px;
                        font-size: rem(29px);
                        span {
                            margin-left: rem(20px);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    /deep/ {
        .page-banner {
            --h: 590px;
        }
    }
}

// ar style

.ar {
    .mentorDashboard {
        $self: &;
        &__summary {
            padding-right: rem(65px);
            padding-left: rem(80px);
            #{ $self }--addtask {
                .icon {
                    margin-left: rem(20px);
                    margin-right: 0;
                }
            }
            &--left {
                .nav-item {
                    &:not(:last-child) {
                        margin-left: rem(30px);
                        margin-right: 0;
                    }
                }
            }
        }
        /deep/.addTask {
            .v-select-toggle {
                .arrow-down {
                    left: 35px;
                    right: auto;
                }
            }
        }
        .mentorDashboard__summary .mentorDashboard--addtask .icon {
            margin-left: rem(20px);
            margin-right: 0;
        }
        @media screen and (max-width: 1199px) {
            &__summary {
                padding-right: rem(45px);
                padding-left: rem(45px);
            }
        }
        @media screen and (max-width: 767px) {
            &__summary--left {
                /deep/.nav-tabs {
                    .nav-item {
                        .nav-link {
                            span {
                                margin-right: rem(20px);
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
